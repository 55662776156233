<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12" md="3" lg="3" sm="12">
            <v-alert text color="success" outlined height="120">
              <v-row>
                <v-col class="mt-3">
                  <h1 class="text-center">{{ widget.total_sale.toLocaleString() }}</h1>
                  <p class="text-center">سەرجەم فرۆشتنەکان</p>
                </v-col>
                <v-col class="text-left"><v-icon size="100" color="success">mdi-currency-usd</v-icon></v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="3" lg="3" sm="12">
            <v-alert text color="warning" outlined height="120">
              <v-row>
                <v-col class="mt-3">
                  <h1 class="text-center">{{ widget.total_purchase.toLocaleString() }}</h1>
                  <p class="text-center">سەرجەم کڕینەکان</p>
                </v-col>
                <v-col class="text-left"><v-icon size="100" color="warning">mdi-currency-usd</v-icon></v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="3" lg="3" sm="12">
            <v-alert text color="error" outlined height="120">
              <v-row>
                <v-col class="mt-3">
                  <h1 class="text-center">{{ widget.total_expense.toLocaleString() }}</h1>
                  <p class="text-center">سەرجەم خەرجییەکان</p>
                </v-col>
                <v-col class="text-left"><v-icon size="100" color="error">mdi-currency-usd</v-icon></v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="3" lg="3" sm="12">
            <v-alert text color="indigo" outlined height="120">
              <v-row>
                <v-col class="mt-3">
                  <h1 class="text-center">{{ widget.total_debt.toLocaleString() }}</h1>
                  <p class="text-center">سەرجەم قەرزەکان</p>
                </v-col>
                <v-col class="text-left"><v-icon size="100" color="indigo">mdi-currency-usd</v-icon></v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <v-card>
          <v-chart class="chart" :option="option" autoresize style="direction: ltr" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  MarkPointComponent,
]);
export default {
  components: { VChart },
  data() {

    return {
      widget: {
        total_sale: 0,
        total_purchase: 0,
        total_expense: 0,
        total_debt: 0
      },
      option: {
        color: ['green', 'red'],
        textStyle: {
          fontFamily: 'NRT'
        },
        title: {
          text: 'ئاماری فرۆشتن و خەرجی 5 مانگ پێش ئێستا',
          right: 0
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['فرۆشتن', 'خەرجی']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
          left: 'left'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'فرۆشتن',
            type: 'line',
            data: []
          },
          {
            name: 'خەرجی',
            type: 'line',


            data: []
          },
        ]
      },
    }
  },
  methods: {
    async getSaleAndExpense() {
      const { data } = await this.axios.get('/report/saleAndExpense')
      this.option.series[0].data = data.map(sae => Number(sae.sale));
      this.option.series[1].data = data.map(sae => Number(sae.expense));
      this.option.xAxis.data = data.map(sae => String(sae.date))
    },
    async getWidgetData() {
      const { data } = await this.axios.get('/report/topWidgetDashboard')
      this.widget = data
    }
  },
  created() {
    this.getSaleAndExpense()
    this.getWidgetData()
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>